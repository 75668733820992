export const homeObjAbout = {
  id: "about",
  lightBg: true,
  darkText: true,
  lightTextDesc: true,
  topLine: "Who We Are",
  headline: "",
  text:
    "Whether you own a small, midsize, corporate business or large enterprise, we can develop both Web or Mobile App Solutions that turns your goal to a success.",
  textMore:
    "We are committed to staying ahead of the ever-evolving digital landscape. Our objective is to constantly innovate and adapt to the latest technologies, strategies, and best practices in the digital realm. We aim to be at the forefront of the industry, offering cutting-edge solutions that help our clients stay competitive in the digital world and achieve their online goal.",
  buttonLabel: "Read more",
  imgStart: false,
  img: require("../../images/svg-hosting.svg").default,
  alt: "Car",
  dark: true,
  primary: true,
};

export const homeObjCustomerService = {
  id: "support",
  lightBg: true,
  lightTextDesc: false,
  topLine: "Exceptional Support",
  headline: "Customer Service",
  text:
    "We prioritize providing exceptional customer service to our clients. Our objective is to build long-term partnerships based on trust, mutual respect, and success. We strive to understand our clients' unique needs, provide timely and responsive support, and exceed their expectations in every interaction.",
  buttonLabel: false,
  imgStart: false,
  img: require("../../images/svg-2.svg").default,
  alt: "Location",
  dark: false,
  primary: true,
  darkText: true,
};

export const homeObjClientsFocus = {
  id: "clients",
  lightBg: false,
  lightTextDesc: false,
  topLine: "We Drive Business Growth",
  headline: "Client Focus",
  text:
    "Our primary objective is to help our clients drive business growth through our interactive digital solutions, including SaaS, SEO, website development and digital marketing. We are committed to delivering tangible results that positively impact our clients' online presence, brand visibility, and revenue generation",
  buttonLabel: false,
  imgStart: true,
  img: require("../../images/svg-3.svg").default,
  alt: "Clients",
  dark: false,
  primary: false,
  darkText: false,
};

export const logo = {
  alt: "Metier Digital Solutions - MDS Logo",
  img: require("../../images/logo-w.webp"),
};

export const serviceData = [
  {
    default: "branding",
    id: "services",
    lightBg: true,
    lightTextDesc: false,
    topLine: "Our Services",
    headline: "What We Do",
    buttonLabel: "View More",
    dark: false,
    primary: false,
    darkText: false,
    text:
    "We design products with a strong focus on both world class design and ensuring your product is a market success.",
    textMore: "",
  },
  [
    {
      tip: "branding",
      service: "Corporate Branding",
      subServices: "Logo Design, Portfolio Design, Company Profile design, Social media banner design, Brand Awareness Campaigns Sponsored Posts, Blog post writing, Social Media writing",
    },
    {
      tip: "marketing",
      service: "Digital Marketing",
      subServices: "Social Media Campaign, Google Business Account optimization, Google adwards campaign, Social media account management, Youtube account management, Search Engine Optimization, On page & Off page SEO, Email Marketing & Automation, AI Lead generation & Automation, Video Editing and Public Relations Outreach",
    },
    {
      tip: "web",
      service: "App & Web Development",
      subServices: "Business analysis & Consultation, Business & eCommerce website development, Shopping cart & Payment gateway, API integration, State of the art web design, High converting & performing UX, Brand Authority, Website security, Monthly Maintenance",
    },
    // {
    //   tip: "mobile",
    //   service: "Mobile Apps",
    //   subServices: "",
    // },
    // {
    //   tip: "customApp",
    //   service: "Custom Software Developments",
    //   subServices: "",
    // },
    // {
    //   tip: "hosting",
    //   service: "Cloud Hosting",
    //   subServices: "In the age of digital transformation, traditional hosting solutions no longer suffice. Our cloud hosting services provide a reliable, scalable, and secure infrastructure to support your business operations. We leverage leading cloud platforms to deliver flexible hosting solutions tailored to your specific needs. Whether you require hosting for a small website or a complex enterprise application, our team ensures high availability, fast performance, and robust security. By embracing cloud hosting, you can streamline your IT infrastructure, reduce costs, and focus on driving innovation and growth.",
    // },
    // {
    //   tip: "hardware",
    //   service: "Hardware Maintenance",
    //   subServices: "Hardware plays a crucial role in maintaining the smooth functioning of your business operations. Our hardware maintenance services provide comprehensive support to keep your systems running efficiently. We offer proactive monitoring, regular maintenance, and timely repairs to minimize downtime and ensure maximum uptime for your critical hardware components. Our skilled technicians are well-versed in troubleshooting a wide range of hardware issues, providing quick resolutions and minimizing disruptions. By entrusting your hardware maintenance to us, you can focus on your core business activities, knowing that your infrastructure is in capable hands.",
    // }
  ],
];
