import React, { useEffect, useState } from "react";

// Hambuger Icon
import { FaBars } from "react-icons/fa";

// Hambuger Icon Color
import { IconContext } from "react-icons/lib";

// Scroll to top when toggle on logo
import { animateScroll as scroll } from "react-scroll";

import { HashLink as Link } from 'react-router-hash-link';

import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLink,
  // NavLinkS,
  // NavBtn,
  NavBtnLink,
  Img,
} from "./NavbarElements";

const Navbar = ({ toggle, img, alt }) => {
  //  Change Navbar color on page scrolls vertically by 80px
  const [scrollNav, setScrollNav] = useState(false);
  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  // Scroll to the top
  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <Nav scrollNav={scrollNav}>
          <NavbarContainer>
            <NavLogo to="/" onClick={toggleHome}>
              <Img src={img} alt={alt} />
            </NavLogo>
            <MobileIcon onClick={toggle}>
              <FaBars />
            </MobileIcon>
            <NavMenu>
              <NavItem>
                <NavLink
                  to="/services"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  Our Services
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="/projects"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  Our Portfolio
                </NavLink>
              </NavItem>
              <NavItem>
                <Link
                  className="flex items-center px-[1rem] h-[100%]"
                  to="/#clientsLogo"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  Our Clients
                </Link>
              </NavItem>
              {/* <Link to="home-page#section-three">Section three</Link> */}
              <NavBtnLink to="/contact">Get in Touch</NavBtnLink>
            </NavMenu>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
};

export default Navbar;
