import React, { useEffect, useState } from 'react'
import { useInterval } from 'react-use'
import styled, { keyframes } from 'styled-components'

import { serviceData } from './InfoSection/Data';

export default function TextAnimation() {
  const serviceArray = serviceData[1][0].service.split("");
  // const serviceArray = "Metier".split("");
  const socialArray = "Social Media".split("");
  const [splitItem, setSplitItem] = useState(serviceArray)
  const [count, setCount] = useState(0)
  const [play, setPlay] = useState(false)

  useInterval(
    () => {
        // Animate Branding
        setCount(count + 1)
        if (count == serviceData[1].length -1) {
            setCount(0)
        }
        setSplitItem(serviceData[1][count].service.split(""))
        // Animate Social, Looping
    },
    play ? 6000 : null
  )

  useEffect(() => {
    const timer = setTimeout(() => {
        // Animate Social after timeout
        setSplitItem(socialArray)
        setPlay(true)
    }, 4000)

    return () => clearTimeout(timer)
  }, [])

  return <Wrapper className="mt-2">
    {/* {splitItem.map((item, index) => (
        <span key={index}>{item}</span>
    ))} */}
    <strong className="text-xl lg:text-2xl">We Do </strong> <span className="text-xl lg:text-2xl">{splitItem}</span>
  </Wrapper>
}

const animation = keyframes`
    0% { opacity: 1; transform: translateY(-100px) skewY(10deg) skewX(10deg) rotateZ(30deg); filter: blur(10px); }
    25% { opacity: 1; transform: translateY(0px) skewY(0deg) skewX(0deg) rotateZ(0deg); filter: blur(0px); }
    75% { opacity: 0; transform: translateY(0px) skewY(0deg) skewX(0deg) rotateZ(0deg); filter: blur(0px); }
    100% { opacity: 1; transform: translateY(-100px) skewY(10deg) skewX(10deg) rotateZ(30deg); filter: blur(10px); }
`

const Wrapper = styled.span`
    display: inline-block;
    span {
        display: inline;
        opacity: 0;
        margin: 0 2px;
        animation-name: ${animation};
        animation-duration: 6s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
    }
`
