import React, { useState } from "react";
import { logo } from "../InfoSection/Data";
// import { Img } from "../Navbar/NavbarElements";
import {
  Container,
  FormButton,
  FormContent,
  FormLabel,
  FormWrap,
  // Icon,
  Form,
  FormH1,
  FormInput,
  Text,
} from "./SigninElements";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
// import Footer from "../Footer";

const SignIn = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
        <Sidebar isOpen={isOpen} toggle={toggle} />
        <Navbar toggle={toggle} {...logo} />
      <Container>
        <FormWrap>
          {/* <Icon to="/">
            <Img src={logo.img} alt={logo.alt} />
          </Icon> */}
          <FormContent>
            <Form action="#">
              <FormH1>Sign in to your account</FormH1>
              <FormLabel htmlFor="for">Email</FormLabel>
              <FormInput type="email" required />
              <FormLabel htmlFor="for">Password</FormLabel>
              <FormInput type="password" required />
              <FormButton type="submit">Continue</FormButton>
              <Text>Forgot password</Text>
            </Form>
          </FormContent>
        </FormWrap>
      </Container>
        {/* <Footer {...logo} /> */}
    </>
  );
};

export default SignIn;
