import React, { useState } from "react";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import { logo } from "./InfoSection/Data";
import Footer from "./Footer";

import {
  // HeroBg,
  HeroContainer,
  HomeHeroContent,
  HomeHeroH1,
  // HomeHeroP,
  HeroLeft,
  HeroRight,
} from "./HeroSection/HeroElements";

import { motion } from "framer-motion";
import { styles } from "../styles";
import { staggerContainer } from "../utils/motion";

const LayoutWrapper = (Component, pageName) =>
  function Layout()
  {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
      setIsOpen(!isOpen);
    };

    return (
      <>
        <Sidebar isOpen={isOpen} toggle={toggle} />
        <Navbar toggle={toggle} {...logo} />
        <HeroContainer>
          <HeroLeft>
            <HomeHeroContent>
              <HomeHeroH1>{pageName.toUpperCase()}</HomeHeroH1>
            </HomeHeroContent>
          </HeroLeft>
          <HeroRight></HeroRight>
        </HeroContainer>

        <motion.section
          variants={staggerContainer()}
          initial="hidden"
          whileInView="show"
          viewport={{ once: true, amount: 0.25 }}
          className={`${styles.padding} max-w-7xl mx-auto relative z-0`}
        >
          <span className="hash-span" id={pageName}>
            &nbsp;
          </span>

          <Component />
        </motion.section>

        <Footer {...logo} />
      </>
    );
  };

export default LayoutWrapper;
