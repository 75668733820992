import styled from "styled-components";
import Hero from "../../images/heromain1.webp";

export const Section = styled.div`
  padding: 30px 50px;
  background: #000;
  /* height: 120vh; */
  scroll-snap-align: center;
  /* display: flex; */
  justify-content: center;
  position: relative;
  color: black;
  font-size: 14px;
  font-weight: 300;
  height: fit-content;

  @media only screen and (max-width: 768px) {
    padding: 0 20px;
    /* height: fit-content; */
  }
`;

export const Container = styled.div`
  color: white;
  /* width: 1400px; */
  /* column-gap: -100px; */
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 768px) {
    width: 100%;
    flex-direction: column;
  }
`;

export const ServicesList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 48px;

  @media only screen and (max-width: 768px) {
    padding: 0 0;
  }
`;

export const ServicesListItem = styled.li`
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  color: transparent;
  -webkit-text-stroke: 1px #e18707;
  position: relative;

  @media only screen and (max-width: 768px) {
    font-size: 22px;
  }

  &::after {
    content: "${(props) => props.text}";
    position: absolute;
    top: 0;
    left: 0;
    color: white;
    width: 0px;
    overflow: hidden;
    white-space: nowrap;
  }

  &:hover {
    width: 100%;
    &::after {
      animation: moveText 0.5s linear both;

      @keyframes moveText {
        to {
          margin-left: 40px;
          width: 100%;
        }
      }
    }
  }

  &.active {
    color: orange;
  }
`;

export const Left = styled.div`
  flex: 1;
  display: flex;
  align-items: center;

  height: fit-content;

  @media only screen and (max-width: 768px) {
    padding: 20px;
    justify-content: center;
  }
`;

export const CanvaSection = styled.div`
  max-height: 60vh;
  flex: 1;
  width: 100%;
  background-image: url(${Hero});

  @media only screen and (max-width: 768px) {
    /* position: absolute; */
    /* display: flex; */
    /* top: 50%; */
    /* bottom: 0; */
    /* left: 300px; */
    /* right: 0; */
    /* margin: auto; */
  }
`;

export const Desc = styled.div`
  /* flex: 1; */
  width: 200px;
  height: 70px;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  position: absolute;
  left: 40%;
  color: black;
  /* bottom: 200px; */
  top: 100px;

  @media only screen and (max-width: 768px) {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
`;
