import React from "react";

import { BallCanvas } from "./canvas";
// import LayoutWrapper from "./Layout";
import { clients } from "../constants";
// import { motion } from "framer-motion";
// import { textVariant } from "../utils/motion";
// import { styles } from "../styles";
import { Heading, TopLine } from "./InfoSection/InfoElements";
// import { Section } from "./Contact/ContactElements";

import Carousel from "react-grid-carousel";

const ClientsLogo = () => {
  return (
    <>
      <div className="ml-[50px]" id="clientsLogo">
        <TopLine className="lg:text-center mt-[50px]">
          A few of the clients we have worked for
        </TopLine>
        <Heading darkText={false} className="lg:text-center">
          Clients
        </Heading>
      </div>

      <div className="flex_ flex-row_ flex-wrap_ justify-center_ gap-10_ mb-[50px] px-[30px] lg:px-[80px]">
        <Carousel cols={5} rows={1} gap={1} loop={true} autoplay={5000}>
          {clients.map((technology) => (
            <Carousel.Item>
              <div className="w-28 h-28" key={technology.name}>
                <BallCanvas icon={technology.icon} />
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </>
  );
};

export default ClientsLogo;
