import React, { useState } from "react";
import styled from "styled-components";
import { serviceData } from "../../components/InfoSection/Data";
import {
  Container,
  Section,
  ServicesList,
  ServicesListItem,
  Left,
  CanvaSection,
} from "../../components/Services/ServicesElements";

import { motion, AnimatePresence } from "framer-motion";
import Arrow from "../Arrow";
// import MacPcCanvas from "../canvas/Mac";
// import SoftwareCanvas from "../canvas/Software";
// import ServerCanvas from "../canvas/Server";
// import SocialCanvas from "../canvas/Social";
// import BrandingCanva from "../canvas/Branding";
// import HardwareCanva from "../canvas/Hardware";

// import Right from "../../components/Services/Right";

// const Subtitle = styled.div`
//   text-align: center;
//   transition: transform 0.4s;
//   transform-style: preserve-3d;
//   background-color: transparent;

//   transition: all 0.25s ease-in-out;

//   width: 100%;
//   border-radius: 5px;
//   border: 1px solid;
//   border-style: ridge;
//   margin-bottom: 35px;
//   font-size: 16px;
//   line-height: 24px;
//   position: relative;
//   margin: 20px 300px;
//   color: ${({ darkText }) => (darkText ? "#010606" : "#f7f8fa")};
// `;

export const TextWrapper = styled.div`
_max-width: 540px;
padding-top: 30px;
padding-bottom: 1px;

@media screen and (min-width: 913px) {
    display:flex;
    justify-content:space-between;
  }
`;

const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: ${({ darkText }) => (darkText ? "#010606" : "#f7f8fa")};

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

const TopLine = styled.p`
  color: #e18707;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin: 16px;
`;

const Services = () => {
  const [clicked, setClicked] = useState(serviceData[0].default);
  const [subServices, setSubServices] = useState(serviceData[0].subServices);

  const [expanded, setExpanded] = useState(false);

  const handleClick = (data) => {
    setClicked(data.tip);
    setSubServices(data.subServices);

    if(clicked === data.tip){
      setExpanded(!expanded);
    }
    else {
      setExpanded(true);
    }
  };

  return (
    <>
      <Section>    
        <TextWrapper>
          <TopLine>{serviceData[0].topLine}</TopLine>
          <Heading darkText={serviceData[0].darkText} className="mr-[95px]">
            {serviceData[0].headline}
          </Heading>
        </TextWrapper>
        <Container>
            {/* // <ServerCanvas /> */}
          <CanvaSection>
            {
              clicked === "marketing" ? (
                <></>
                // <SoftwareCanvas />
              ) : clicked === "web" ? (
                <></>
                // <MacPcCanvas />
              ) : clicked === "branding" ? (
                <></>
                // <BrandingCanva /> 
              ) : (
                <></> 
                // <HardwareCanva />
              )
            }
          </CanvaSection>

          <Left>
            <ServicesList>
              {serviceData[1].map((data) => (
                <>
                  <ServicesListItem
                    animate={expanded ? "collapsed" : "open"}
                    variants={{
                      open: { opacity: 1 },
                      collapsed: { opacity: 0.4 },
                    }}
                    key={data.tip}
                    text={data.service}
                    onClick={() => handleClick(data)}
                  >
                    {clicked === data.tip && <Arrow />}
                    <p className="ml-[40px]">{data.service}</p>
                  </ServicesListItem>
                  {clicked === data.tip && (
                    <AnimatePresence initial={false}>
                      {expanded && (
                        <motion.section
                          initial="collapsed"
                          animate="open"
                          exit="collapsed"
                          variants={{
                            open: { opacity: 1, height: "auto" },
                            collapsed: { opacity: 0, height: 0 },
                          }}
                          transition={{
                            type: "spring",
                            stiffness: 200,
                            damping: 30,
                          }}
                          style={{ overflow: "hidden" }}
                          className="ml-[40px]"
                        >
                          { 
                            subServices.split(",").map(service => (
                              <li className="pb-2 list-disc list-inside" key={service}>{service}</li> 
                            ))
                          }
                        </motion.section>
                      )}
                    </AnimatePresence>
                  )}
                </>
              ))}
            </ServicesList>
          </Left>
        </Container>
      </Section>
    </>
  );
};

export default Services;
