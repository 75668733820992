import React, { useEffect, useState } from "react";
import { Button } from "../ButtonElements";

import { styled } from "styled-components";

import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  BtnWrap,
  ImgWrap,
  Img,
} from "./InfoElements";
import { motion, useAnimation, AnimatePresence } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Tilt } from "react-tilt";

const TextMore = styled.div`
  margin: 15px 0;
`;

const InfoSection = (props) => {
  const initial = {
    scale: 0.2,
    y: 40,
    opacity: 0,
  };

  const animation = useAnimation();

  const { ref, inView } = useInView({
    threshold: 0.4,
  });

  useEffect(() => {
    if (inView) {
      animation.start({
        scale: 1,
        y: 0,
        opacity: 1,
      });
    }
  }, [inView, animation]);

  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <InfoContainer lightBg={props.infoData.lightBg} id={props.infoData.id}>
        <InfoWrapper>
          <InfoRow ref={ref} imgStart={props.infoData.imgStart}>
            <Column1>
              <TextWrapper
                initial={initial}
                animate={animation}
                transition={{ duration: 0.7 }}
              >
                <TopLine>{props.infoData.topLine}</TopLine>
                <Heading darkText={props.infoData.darkText}>
                  {props.infoData.headline}
                </Heading>
                <Subtitle darkText={props.infoData.darkText}>
                  {props.infoData.text}
                  <AnimatePresence initial={false}>
                    {expanded && (
                      <motion.section
                        initial="collapsed"
                        animate="open"
                        exit="collapsed"
                        variants={{
                          open: { opacity: 1, height: "auto" },
                          collapsed: { opacity: 0, height: 0 },
                        }}
                        transition={{
                          type: "spring",
                          stiffness: 200,
                          damping: 30,
                        }}
                        style={{ overflow: "hidden" }}
                      >
                        <TextMore>{props.infoData.textMore}</TextMore>
                      </motion.section>
                    )}
                  </AnimatePresence>
                </Subtitle>

                {
                  /* {props.serviceData && <Services serviceData={props.serviceData} />} */
                  props.infoData.buttonLabel && (
                    <BtnWrap>
                      <Button
                        // to="home"
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact="true"
                        offset={-80}
                        primary={props.infoData.primary ? 1 : 0}
                        dark={props.infoData.dark ? 1 : 0}
                        dark2={props.infoData.dark2 ? 1 : 0}
                        onClick={() => setExpanded(!expanded)}
                      >
                        {expanded ? "Read Less" : props.infoData.buttonLabel}
                      </Button>
                    </BtnWrap>
                  )
                }
              </TextWrapper>
            </Column1>
            <Column2>
              <Tilt>
                <ImgWrap>
                  <Img src={props.infoData.img} alt={props.infoData.alt} />
                </ImgWrap>
              </Tilt>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default InfoSection;
