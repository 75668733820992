import styled from "styled-components";
import { MdKeyboardArrowRight, MdArrowForward } from "react-icons/md";

import Hero from "../../images/hero.webp";

export const HomeHeroContainer = styled.div`
  background: #0c0c0c;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  height: 600px;
  position: relative;
  z-index: 1;

  /* Darker Hero */
  :before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0.6) 100%
      ),
      linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%);
    z-index: 2;
  }
`;

export const VideoBg = styled.video`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  background: #232a34;
`;

// export const HeroBg = styled.img`
//   width: 100%;
//   height: 100%;
//   -o-object-fit: cover;
//   object-fit: cover;
//   background: #232a34;
// `;

export const HomeHeroContent = styled.div`
  z-index: 3;
  max-width: 1200px;
  position: absolute;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HomeHeroH1 = styled.h1`
  color: #fff;
  font-size: 48px;
  font-weight: bold;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 40px;
    position: absolute;
    top: -120px;
    color: #fff;
  }

  @media screen and (max-width: 480px) {
    font-size: 32px;
    color:#fff;
  }
`;

export const HomeHeroP = styled.p`
  margin-top: 24px;
  color: #e18707;
  font-size: 24px;
  font-weight: 900;
  text-align: center;
  max-width: 600px;

  @media screen and (max-width: 760px) {
    font-size: 24px;
  }

  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`;

export const HomeHeroBtnWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ArrowForward = styled(MdArrowForward)`
  margin-left: 8px;
  font-size: 20px;
`;

export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left: 8px;
  font-size: 20px;
`;

export const Char = styled.span`
  // color: #fff;
`;

export const HomeHeroBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const HeroLeft = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  background: #000;
  padding: 0 40px;

  @media only screen and (max-width: 768px) {
    padding: 20px;
    justify-content: center;
  }
`;

export const HeroRight = styled.div`
  /* min-height: 20vh; */
  flex: 2;
  /* width: 50px; */
  /* z-index: 3; */
  background-image: url(${Hero});

  @media only screen and (max-width: 768px) {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* width: 100%; */
  }

  @media screen and (max-width: 480px) {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -350px;
    right: 0;
  }

  @media screen and (max-width: 2668px) {
    /* position: absolute; */
    /* top: 0;
    bottom: 0; */
    /* left: 20px; */
    /* right: 0; */
    /* width: 500%; */
    background-repeat: no-repeat;
  }
`;

export const HeroContainer = styled.div`
  /* color: white; */
  /* width: 1400px; */
  /* column-gap: -100px; */
  height: 500px;
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 768px) {
    width: 100%;
    flex-direction: column;
  }
`;
