import React, { useEffect } from "react";
import { styled } from "styled-components";

const Arrow = () => {
  useEffect(() => {
    // const $icon = document.querySelector(".icon");
    const $arrow = document.querySelector(".arrow");
    $arrow.animate([{ left: "0" }, { left: "50px" }, { left: "0" }], {
      duration: 1900,
      iterations: Infinity,
    });
  }, []);

  const Icon = styled.div`
    position: absolute;
    top: 50%;
    left: 2%;
    transform: translate(-50%, -50%);
    width: 80px;
    height: 60px;
    cursor: pointer;
  `;

  const Arrow = styled.div`
    position: absolute;
    top: 30px;
    width: 30%;
    height: 5px;
    background-color: #fff;
    box-shadow: 0 3px 5px rgba(0, 0, 0, .2);
    animation: arrow 700ms linear infinite;

    &::after,
    &::before {
      content: '';
      position: absolute;
      width: 50%;
      height: 5px;
      right: -4px;
      background-color: #fff;
    }
    &::after {
      top: -3px;
      transform: rotate(45deg);
    }
    &::before {
      top: 3px;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
      transform: rotate(-45deg);
    }
  `;

  return <Icon className="icon">
    <Arrow className="arrow"></Arrow>
  </Icon>;
};

export default Arrow;
