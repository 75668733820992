import React from "react";
// Scroll to top when toggle on logo
import { animateScroll as scroll } from "react-scroll";

import { HashLink as Link } from "react-router-hash-link";

import {
  FooterContainer,
  FooterWrap,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  WebsiteRights,
  SocialIcons,
  SocialIconLink,
  Span,
  BottomText,
  BottomLabel,
} from "./FooterElements";

import { Img } from "../Navbar/NavbarElements";

import {
  FaTwitter,
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaLinkedin,
} from "react-icons/fa";

const Footer = ({ img, alt }) => {
  // Scroll to the top
  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <FooterContainer>
      <FooterWrap>
        <FooterLinksContainer>
          {/* Column 1 */}
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>About Us</FooterLinkTitle>
              <Link
                className="flex items-center mb-[0.5rem] _h-[20%]"
                to="/#clientsLogo"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-80}
              >
                Clients
              </Link>
              <FooterLink to="#testimonials">Testimonials</FooterLink>
              <FooterLink to="#careers">Careers</FooterLink>
              <FooterLink to="#investors">Investors</FooterLink>
              <FooterLink to="#terms">Terms of Service</FooterLink>
            </FooterLinkItems>
            <FooterLinkItems>
              <FooterLinkTitle>Social Media</FooterLinkTitle>
              <FooterLink
                to="//www.instagram.com/metierdigitals"
                target="_blank"
              >
                Instagram
              </FooterLink>
              <FooterLink
                to="//www.facebook.com/metierdigitals"
                target="_blank"
              >
                Facebook
              </FooterLink>
              <FooterLink to="//www.youtube.com/metierdigitals" target="_blank">
                Youtube
              </FooterLink>
              <FooterLink to="//www.twitter.com/metierdigitals" target="_blank">
                Twitter
              </FooterLink>
            </FooterLinkItems>
          </FooterLinksWrapper>

          {/* Column 2 */}
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>Contact Us</FooterLinkTitle>
              <FooterLink to="/contact">Get in Touch</FooterLink>
              <FooterLink to="/contact">Support</FooterLink>
              <FooterLink to="#partnership">Partnership</FooterLink>
              <BottomLabel>Phone</BottomLabel>
              <BottomText>+2348124619885</BottomText>
              <BottomText>+447876717047</BottomText>
              <BottomLabel>Email</BottomLabel>
              <BottomText>info@metierdigitals.com</BottomText>
            </FooterLinkItems>
            <FooterLinkItems>
              <FooterLinkTitle>Services</FooterLinkTitle>
              <FooterLink to="/services">Corporate Branding</FooterLink>
              <FooterLink to="/service">Social Media Management</FooterLink>
              <FooterLink to="/service">Software Development</FooterLink>
              <FooterLink to="/service">Mobile & Hardware</FooterLink>
            </FooterLinkItems>
          </FooterLinksWrapper>
        </FooterLinksContainer>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to="/" onClick={toggleHome}>
              <Img src={img} alt={alt} />
            </SocialLogo>
            <WebsiteRights>
              <Span>Metier Digital Solutions</Span>
              <p>&copy; {new Date().getFullYear()}. All rights reserved.</p>
            </WebsiteRights>
            <SocialIcons>
              <SocialIconLink
                href="//www.facebook.com/metierdigitals"
                target="_blank"
                aria-label="Facebook"
              >
                <FaFacebook />
              </SocialIconLink>
              <SocialIconLink
                href="//www.instagram.com/metierdigitals"
                target="_blank"
                aria-label="Instagram"
              >
                <FaInstagram />
              </SocialIconLink>
              <SocialIconLink
                href="//www.youtube.com/metierdigitals"
                target="_blank"
                aria-label="Youtube"
              >
                <FaYoutube />
              </SocialIconLink>
              <SocialIconLink
                href="//www.twitter.com/metierdigitals"
                target="_blank"
                aria-label="Twitter"
              >
                <FaTwitter />
              </SocialIconLink>
              <SocialIconLink href="/" target="_blank" aria-label="Linkedin">
                <FaLinkedin />
              </SocialIconLink>
            </SocialIcons>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
