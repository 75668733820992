import {
  creator,
  web,
  carrent,
  jobit,
  tripguide,
  hosting,
  media,
  software,
  devices,
  lsm,
  brandedge,
  apex,
  brainpiece,
  mds,
  dalmoenergy,
  brewserv,
  rdci
} from "../assets";

export const navLinks = [
  {
    id: "about",
    title: "About",
  },
  {
    id: "work",
    title: "Work",
  },
  {
    id: "contact",
    title: "Contact",
  },
];

// const services1 = [
//   {
//     title: "Web Developer",
//     icon: web,
//   },
//   {
//     title: "React Native Developer",
//     icon: mobile,
//   },
//   {
//     title: "Backend Developer",
//     icon: backend,
//   },
//   {
//     title: "Content Creator",
//     icon: creator,
//   },
// ];

const clients = [
  {
    name: "Brainpiece",
    icon: brainpiece,
  },
  {
    name: "LSM",
    icon: lsm,
  },
  {
    name: "Brand Edge",
    icon: brandedge,
  },
  {
    name: "Apex Link GC",
    icon: apex,
  },
  {
    name: "Metier Digital Solutions",
    icon: mds,
  },
  {
    name: "Dalmore Energy",
    icon: dalmoenergy,
  },
  {
    name: "Brewserv Limited",
    icon: brewserv,
  },
  {
    name: "RDCI Church",
    icon: rdci,
  },
];

const services = [
  {
    title: "Cloud Hosting",
    company_name: "Metier Digital Solutions",
    icon: hosting,
    iconBg: "#383E56",
    date: "We leverage leading cloud platforms to deliver flexible hosting solutions tailored to your specific needs",
    text: "Our cloud hosting services provide a reliable, scalable, and secure infrastructure to support your business operations. We leverage leading cloud platforms to deliver flexible hosting solutions tailored to your specific needs."
  },
  {
    title: "Corporate Branding",
    company_name: "Metier Digital Solutions",
    icon: creator,
    iconBg: "#E6DEDD",
    date: "We work closely with you to understand your values, goals, and unique selling propositions.",
    text: "Your corporate brand is a vital asset that distinguishes your business from competitors and resonates with your target audience. Our corporate branding services help you craft a strong and compelling brand identity. Our team develops cohesive branding strategies encompassing logo design, brand guidelines, messaging, and visual elements. By aligning your brand across all touchpoints, you can establish trust, foster customer loyalty, and elevate your business to new heights."
  },
  {
    title: "Web Development",
    company_name: "Metier Digital Solutions",
    icon: web,
    iconBg: "#383E56",
    date: "Dynamic & Mobile Responsive",
    text: "In today's digital world, having an appealing and user-friendly website is crucial for any business. Our expert web design team specializes in creating stunning websites that not only capture the essence of your brand but also provide an exceptional user experience. We combine cutting-edge design principles with the latest web technologies to deliver websites that are visually captivating, mobile-responsive, and optimized for search engines. By partnering with us for your web design needs, you can establish a strong online presence, attract more visitors, and ultimately convert them into loyal customers."
  },
  {
    title: "Social Media Services",
    company_name: "Metier Digital Solutions",
    icon: media,
    iconBg: "#E6DEDD",
    date: "Our social media services are designed to help you effectively navigate the ever-changing social media landscape",
    text: "Social media has become a powerful platform for businesses to engage with their target audience and build brand awareness. Our social media services are designed to help you effectively navigate the ever-changing social media landscape. We develop comprehensive social media strategies tailored to your business objectives and target audience. Our team creates compelling content, manages your social media accounts, and runs targeted campaigns to drive engagement, increase followers, and generate leads. With our expertise in social media management, you can amplify your brand's voice, foster customer relationships, and achieve remarkable growth in the digital sphere."
  },
  {
    title: "Enterprise Software",
    company_name: "Metier Digital Solutions",
    icon: software,
    iconBg: "#E6DEDD",
    date: "Our enterprise software development services cater to your specific business needs",
    text: "In today's dynamic business environment, having customized software solutions can provide a competitive edge. Our enterprise software development services cater to your specific business needs, enabling process automation, enhancing productivity, and improving overall efficiency. Our experienced team of developers follows industry best practices to design, develop, and deploy scalable software applications. We ensure seamless integration with your existing systems and provide ongoing support to ensure optimal performance. By leveraging our expertise in enterprise software development, you can optimize workflows, gain actionable insights, and drive growth."
  },
  {
    title: "Hardware Maintenance",
    company_name: "Metier Digital Solutions",
    icon: devices,
    iconBg: "#E6DEDD",
    date: "Our hardware maintenance services provide comprehensive support to keep your systems running efficiently",
    text: "Hardware plays a crucial role in maintaining the smooth functioning of your business operations. Our hardware maintenance services provide comprehensive support to keep your systems running efficiently. We offer proactive monitoring, regular maintenance, and timely repairs to minimize downtime and ensure maximum uptime for your critical hardware components. Our skilled technicians are well-versed in troubleshooting a wide range of hardware issues, providing quick resolutions and minimizing disruptions. By entrusting your hardware maintenance to us, you can focus on your core business activities, knowing that your infrastructure is in capable hands.",
  },
];

const testimonials = [
  {
    testimonial:
      "I thought it was impossible to make a website as beautiful as our product, but Rick proved me wrong.",
    name: "Sara Lee",
    designation: "CFO",
    company: "Acme Co",
    image: "https://randomuser.me/api/portraits/women/4.jpg",
  },
  {
    testimonial:
      "I've never met a web developer who truly cares about their clients' success like Rick does.",
    name: "Chris Brown",
    designation: "COO",
    company: "DEF Corp",
    image: "https://randomuser.me/api/portraits/men/5.jpg",
  },
  {
    testimonial:
      "After Rick optimized our website, our traffic increased by 50%. We can't thank them enough!",
    name: "Lisa Wang",
    designation: "CTO",
    company: "456 Enterprises",
    image: "https://randomuser.me/api/portraits/women/6.jpg",
  },
];

const projects = [
  {
    name: "Car Rent",
    description:
      "Web-based platform that allows users to search, book, and manage car rentals from various providers, providing a convenient and efficient solution for transportation needs.",
    tags: [
      {
        name: "react",
        color: "blue-text-gradient",
      },
      {
        name: "mongodb",
        color: "green-text-gradient",
      },
      {
        name: "tailwind",
        color: "pink-text-gradient",
      },
    ],
    image: carrent,
    source_code_link: "https://github.com/",
  },
  {
    name: "Job IT",
    description:
      "Web application that enables users to search for job openings, view estimated salary ranges for positions, and locate available jobs based on their current location.",
    tags: [
      {
        name: "react",
        color: "blue-text-gradient",
      },
      {
        name: "restapi",
        color: "green-text-gradient",
      },
      {
        name: "scss",
        color: "pink-text-gradient",
      },
    ],
    image: jobit,
    source_code_link: "https://github.com/",
  },
  {
    name: "Trip Guide",
    description:
      "A comprehensive travel booking platform that allows users to book flights, hotels, and rental cars, and offers curated recommendations for popular destinations.",
    tags: [
      {
        name: "nextjs",
        color: "blue-text-gradient",
      },
      {
        name: "supabase",
        color: "green-text-gradient",
      },
      {
        name: "css",
        color: "pink-text-gradient",
      },
    ],
    image: tripguide,
    source_code_link: "https://github.com/",
  },
];

export { clients, services, testimonials, projects };
