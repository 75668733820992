import { motion } from "framer-motion";

import { styles } from "../../styles";
import { ComputersCanvas } from "../canvas";
// import { Char, HomeHeroH1, HomeHeroP } from "./HeroSection/HeroElements";

import TextAnimation from '../TextAnimation'

const Hero = () => {
  return (
    <section className={`relative w-full h-screen mx-auto _bg-red-500`}>
      <div
        className={`absolute inset-0 top-[100px] max-w-7xl mx-auto ${styles.paddingX} flex flex-row items-start gap-5`}
      >
        <div className="flex flex-col justify-center items-center mt-5">
          <div className="w-5 h-5 rounded-full bg-[#E18707]" />
          <div className="w-1 sm:h-80 h-40 violet-gradient z-50" />
        </div>

        <div className="relative top-[30px] z-50">
          <h1 className=
          {
            `${styles.heroHeadText} 
            relative
            text-white text-center
            galaxyFold:text-2xmd
            iPhoneXR:text-4xl
            iPhone12:text-4xl
            pixel7:text-4xl
            samsungGalaxy:text-4xl
            iPadAir:text-6xl
            surfacePro:text-7xl
            surfaceDuo:text-5xl
            nextHubMax:text-7xl
            top-[-25px]
            `
          }>
            Metier Digital <span className="text-[#E18707]">Solutions</span>
          </h1>
          <div className="
          relative 
          md:m-[20px] 
          m-[20px] 
          z-50
          nextHubMax:ml-[305px] 
          nextHubMax:text-2xl 
          nextHubMax:top-[-30px] 
          nextHub:ml-[160px] 
          nextHub:top-[-60px] 
          iPhoneXR:top-[15px] 
          galaxyFold:m-[10px]
          surfaceDuo:m-[30px]
          iPhoneSE:top-[1px]
          iPhone12:top-[10px]
          galaxyFold:top-[10px]
          iPhoneSE:text-xl
          iPhoneXR:text-2xl
          iPhone12:text-2xl
          pixel7:text-2xl
          samsungGalaxy:text-2xl
          nextHub:text-5xl
          iPadAir:text-3xl
          surfacePro:top-[40px]
          xs:top-[-15px]
          ">
            <TextAnimation />
          </div>
        </div>
      </div>

      <span className="
      relative
      xs:top-[-30px]
      sm:top-[100px] 
      iPhoneXR:top-[-190px] 
      md:top-[-250px]
      iPhoneSE:top-[-100px]
      iPhone12:top-[-160px]
      pixel7:top-[-190px]
      samsungGalaxy:top-[-115px]
      iPadAir:top-[-160px]
      iPadPro:top-[-150px]
      surfacePro:top-[-270px]
      surfaceDuo:top-[-70px]
      galaxyFold:top-[-110px]
      nextHub:top-[-30px]
      nextHubMax:top-[-20px]
      _xs:top-[-30px]
      ">
        <ComputersCanvas />
      </span>

      <div className="absolute xs:bottom-10 bottom-32 w-full flex justify-center items-center">
        <a href="#about">
          <div className="w-[35px] h-[64px] rounded-3xl border-4 border-orange-500 flex justify-center items-start p-2">
            <motion.div
              animate={{
                y: [0, 24, 0],
              }}
              transition={{
                duration: 1.5,
                repeat: Infinity,
                repeatType: "loop",
              }}
              className="w-3 h-3 rounded-full bg-orange-500 mb-1"
            />
          </div>
        </a>
      </div>
      Femi
    </section>
  );
};

export default Hero;
