import React, { useState } from "react";
import Hero from "../components/HeroSection/NewHero";
import InfoSection from "../components/InfoSection";
import {
  logo,
  homeObjAbout,
  homeObjCustomerService,
  homeObjClientsFocus,
} from "../components/InfoSection/Data";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import Services from "../components/Services";
import Footer from "../components/Footer";
// import Contact from "../components/Contact";
import ClientsLogo from "../components/ClientsLogo";

// import Toggle from "../components/Demo/Toggle";

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} {...logo} />
      <Hero />
      <InfoSection infoData={homeObjAbout} />
      <Services />
      {/* <Toggle /> */}
      <InfoSection infoData={homeObjCustomerService} />
      <InfoSection infoData={homeObjClientsFocus} />
      <ClientsLogo />
      <Footer {...logo} />
    </>
  );
};

export default Home;
