import React from "react";
import ContactComponent from '../../components/Contact'
import LayoutWrapper from "../../components/Layout";

const Contact = () => {
  // const [isOpen, setIsOpen] = useState(false);

  // const toggle = () => {
  //   setIsOpen(!isOpen);
  // };
  return (
    <>
      <ContactComponent />
    </>
  );
};

export default LayoutWrapper(Contact, "contact");
