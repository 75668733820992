import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import Map from "./Map";

import {
  Section,
  Container,
  Left,
  // Title,
  Form,
  Input,
  TextArea,
  Button,
  Right,
} from "./ContactElements";
import { styles } from "../../styles";
import { motion } from "framer-motion";

import { textVariant } from "../../utils/motion";

const Contact = () => {
  const ref = useRef();
  const [success, setSuccess] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_3hovqdg", "template_zw4tw0t", ref.current, "NCx9brxL2ldA0b2oP")
      .then(
        (result) => {
          console.log(result.text);
          setSuccess(true);
        },
        (error) => {
          console.log(error.text);
          setSuccess(false);
        }
      );
  };
  return (
    <Section>
       <motion.div variants={textVariant()}>
        <p className={`${styles.sectionSubText} lg:ml-[40px] text-orange-400`}>
          Contact
        </p>
        <h2 className={`${styles.sectionHeadText} lg:ml-[40px]`}>
          Get in Touch
        </h2>
      </motion.div>

      <div className="lg:ml-[150px] lg:w-[850px]">
        {/* We prioritize providing exceptional customer service to our clients. Our objective is to build long-term partnerships based on trust, mutual respect, and success. We strive to understand our clients'  unique needs, provide timely and  responsive support, and exceed  their expectations in every  interaction. */}
      </div>

      <Container>
        <Left>
          <Form ref={ref} onSubmit={handleSubmit}>
            {/* <Title>Contact Us</Title> */}
            <Input placeholder="Name" name="name" />
            <Input placeholder="Email" name="email" />
            <TextArea
              placeholder="Write your message"
              name="message"
              rows={10}
            />
            <Button type="submit">Send</Button>
            {success &&
              "Your message has been sent. We'll get back to you soon :)"}
          </Form>
        </Left>
        <Right>
          <Map />
        </Right>
      </Container>
    </Section>
  );
};

export default Contact;
